import React from 'react'
import { useDispatch } from 'react-redux'
import Player from 'react-lazy-youtube'
import { Button } from 'element-react'
import { actions as videoActions } from '../redux/lib/videos'
import styled from 'styled-components'
import { Checkbox } from 'element-react'

const Row = styled.tr`
    &:hover {
        background-color: rgba(46, 245, 208, 0.25);
        transition: 0.5s;
    }

`

const videoId = (url) => {
    const isRawId = !url.includes('.')

    if (!isRawId) {
        const idx = url.indexOf('v=')
        if (url.includes('youtube') && idx > 0) {
            let id = url.substring(idx+2)
            console.log(id)
            if (id.indexOf('&') > 0) {
                id = id.substring(0, id.indexOf('&'))
                console.log(id)
            }
            return id
        } else {
            return null
        }
    } else {
        return url
    }
}

const VideoLink = ({ url }) => {
    const id = videoId(url)
    return id != null ?
        <Player id={id} imageSize="maxresdefault" styles={{
            width: "200px",
            height: "100px"
        }}></Player> : <a href={url}>{url}</a>
}

const DeleteVideo = ({id}) => {
    const dispatch = useDispatch()
    return (
        <Button icon="delete" onClick={() => dispatch(videoActions.deleteVideo(id))}></Button>
    )
}

const WatchedVideo = ({id, watched}) => {
    const dispatch = useDispatch()
    return <Checkbox checked={watched} onChange={() => dispatch(videoActions.toggleVideoWatched(id))} />
}

export default ({video}) => {

    return (
        <Row>
            <td>
                <VideoLink url={video.url}></VideoLink>
            </td>
            <td style={{ textAlign: "center"}} >
                <WatchedVideo id={video.video} watched={video.watched} />
            </td>
            <td>{video.description}</td>
            <td>{video.email}</td>
            <td>
                <DeleteVideo id={video.video}></DeleteVideo>
            </td>
        </Row>
    )
}