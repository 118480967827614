import { createStore, combineReducers, applyMiddleware } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import user, { epics as userEpics } from './lib/user'
import video, { epics as videoEpics } from './lib/videos'

const epicMw = createEpicMiddleware()

const rootEpic = combineEpics(
    userEpics.fetchCurrentUserEpic,
    videoEpics.addVideoEpic,
    videoEpics.loadVideosEpic,
    videoEpics.deleteVideoEpic,
    videoEpics.toggleVideoWatched,
)

const logger = store => next => action => {
  console.log('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  return result
}

const store = createStore(
    combineReducers({
        user,
        video,
    }),
    applyMiddleware(epicMw, logger)
)

epicMw.run(rootEpic)

export default store;