import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Input, Button } from 'element-react'
import { actions as videoActions } from '../redux/lib/videos'

const AddVideo = styled.div`
margin:0 auto;
background-color: rgba(0,0,0,0.06);
max-width:50em;
border:1px solid rgba(0,0,0,0.1);
border-radius:0.5em;
padding:0.5em;
margin-bottom:2em;
`

const Outer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
align-items: center;
row-gap:1em;
margin-bottom:1em;
align-items:center;
justify-items:end;
`
const Row = styled.div`
display: flex;
flex-direction: row;
align-item: center;
`
const Label = styled.div`
font-size:1em;
white-space:nowrap;
padding-right:1em;
`

const ButtonRow = styled.div`
position:relative;
display:flex;
justify-content:flex-end;
`


export default () => {

    const [videoURL, setVideoURL] = useState()
    const [description, setDescription] = useState()
    const dispatch = useDispatch()

    const submit = () => {
        dispatch(videoActions.addVideo({ url: videoURL, description }))
        setVideoURL("")
        setDescription("")
    }

    return (
        <AddVideo>
            <div>Add a video</div>
            <Outer>
                <Label>YouTube URL</Label>
                <Input onChange={setVideoURL} value={videoURL} size="small" />
                <Label>Description</Label>
                <Input onChange={setDescription} value={description} size="small" />
            </Outer>
            <ButtonRow>
                <Button onClick={() => submit()} size="small">Submit</Button>
            </ButtonRow>
        </AddVideo>
    )
}