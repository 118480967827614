// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_content_delivery_bucket": "lunch-videos-20191021143655-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "http://lunch-videos-20191021143655-hostingbucket-dev.s3-website.ca-central-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "ca-central-1:81d7070a-ea53-43f5-b66c-860ede30623e",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_LwFbfLmwA",
    "aws_user_pools_web_client_id": "4cpfnugp8emmim57da3comil9r",
    "oauth": {
        "domain": "lunchvideos-dev.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://lunchandlearn.video/",
        "redirectSignOut": "https://lunchandlearn.video/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
