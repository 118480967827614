import { ofType } from 'redux-observable'
import { switchMap, map, mergeMap, flatMap, concat, catchError, delay } from 'rxjs/operators'
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux'
import { actions as videoActions } from './videos'
import { of, from } from 'rxjs';
import { ConsoleLogger } from '@aws-amplify/core';

export const FETCH_USER_INFO = "FETCH_USER_INFO"
export const SET_USER_INFO = "SET_USER_INFO"

export const actions = {
    setUserInfo : (info) => ({
        type: SET_USER_INFO,
        info
    }),
    fetchUserInfo : () => ({
        type: FETCH_USER_INFO
    }),
}

export const epics = {
    fetchCurrentUserEpic: (action$, state$) => action$.pipe(
        ofType(FETCH_USER_INFO),
        mergeMap(_ => {
            console.log("In mergeMap");
            const stream = from(Auth.currentAuthenticatedUser());
            console.log(stream)
            
            return stream.pipe(
                // delay(500),
                flatMap(user => {
                    console.log("User", user)
                    return [
                        actions.setUserInfo({
                            name : user.attributes.name,
                            email : user.attributes.email,
                            idToken : user.signInUserSession.idToken.jwtToken,
                        }),
                        videoActions.fetchVideos()
                    ]
                }),
                catchError(e => {
                    console.log("Error", e)
                })
            )

            //     return [a1, a2]
            // } catch (e) {
            //     console.log("Ex thrown from Auth.currentAuthenticatedUser", e)
            //     return actions.setUserInfo(null)
            // }
        }),
    ),
}

export const select = {
    useName : () => useSelector(state => {
        console.log("useSelector state", state)
        return state.user.info ? state.user.info.name : null
    }),
    useIsLoggedIn : () => useSelector(state => {
        return state.user.info != null
    }),
}

const INITIAL_STATE = {
    info : null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            console.log("In SET_USER_INFO", action)
            state = {
                ...state,
                info : action.info
            }
            break;
        default:
            break;
    }
    return state;
}

