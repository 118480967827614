import { of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { useSelector } from 'react-redux'
import { ofType } from 'redux-observable'
import { toast } from 'react-toastify'
import { switchMap, catchError, map, filter } from 'rxjs/operators'
import _ from 'lodash'
import config from '../../config'

const ADD_VIDEO = "ADD_VIDEO"
const LOAD_VIDEOS = "LOAD_VIDEOS"
const SET_VIDEOS = "SET_VIDEOS"
const DELETE_VIDEO = "DELETE_VIDEO"
const TOGGLE_VIDEO_WATCHED = "TOGGLE_VIDEO_WATCHED"
const FILTER_WATCHED = "FILTER_WATCHED"
const NO_ACTION = "NO_ACTION"

export const actions = {
    addVideo : ({url, description}) => ({
        type: ADD_VIDEO,
        url,
        description,
    }),

    fetchVideos : () => ({
        type: LOAD_VIDEOS,
    }),

    setVideos : (videos) => ({
        type: SET_VIDEOS,
        videos,
    }),

    deleteVideo : (id) => ({
        type: DELETE_VIDEO,
        id
    }),

    toggleVideoWatched : (id) => ({
        type: TOGGLE_VIDEO_WATCHED,
        id
    }),

    filterWatched : (shouldFilter) => ({
        type: FILTER_WATCHED,
        shouldFilter,
    }),

    noAction : () => ({
        type: NO_ACTION
    })
}

export const select = {

    useVideos: () => useSelector(state => state.video.list),

    useDisplayedVideos: () => useSelector(state => state.video.displayed),

    useVideo: (id) => useSelector(state => _.find(state.video.list, 'id', id))

}


export const epics = {
    addVideoEpic: (action$, state$) => action$.pipe(
        ofType(ADD_VIDEO),
        switchMap(action => {
            const groupId = 0   // TODO: Support groups
            console.log("In ADD_VIDEO")
            console.log(state$)

            return ajax({
                url: config.url + `/videos/${groupId}`,
                method: 'POST',
                headers: {
                    'Authorization' : state$.value.user.info.idToken,
                    'Content-Type' : 'application/json',
                },
                body: {
                    url : action.url,
                    description: action.description
                }
            }).pipe(
                map(response => {
                    console.log("response: ", response)
                    return actions.fetchVideos()
                })
            )
        })
    ),

    loadVideosEpic: (action$, state$) => action$.pipe(
        ofType(LOAD_VIDEOS),
        switchMap(action => {
            const groupId = 0   // TODO: Support groups
            return ajax({
                url: config.url + `/videos/${groupId}`,
                method: 'GET',
                headers: {
                    'Authorization' : state$.value.user.info.idToken,
                    'Content-Type' : 'application/json',
                },
                body: {}
            }).pipe(
                filter(r => r.status == 200),
                map(r => r.response),
                map(data => data.items),
                map(actions.setVideos),
            )
        })
    ),

    deleteVideoEpic: (action$, state$) => action$.pipe(
        ofType(DELETE_VIDEO),
        switchMap(action => {
            const groupId = 0   // TODO: Support groups
            return ajax({
                url: config.url + `/videos/${groupId}/${action.id}`,
                method: 'DELETE',
                headers: {
                    'Authorization' : state$.value.user.info.idToken,
                    'Content-Type' : 'application/json',
                },
                body: {}
            }).pipe(
                filter(r => r.status == 200),
                map(r => r.response),
                map(() => actions.fetchVideos())
            )
        })
    ),
    
    toggleVideoWatched: (action$, state$) => action$.pipe(
        ofType(TOGGLE_VIDEO_WATCHED),
        switchMap(action => {
            
            const groupId = 0   // TODO: Support groups
            const video = _.find(state$.value.video.list, {'video': action.id})

            // Note: The toggle will have already occurred before the epic in the reducer for the same event.
            const watched = (video && typeof(video.watched) !== 'undefined') ? video.watched : true

            // TODO handle case where video is not found.

            return ajax({
                url: config.url + `/videos/${groupId}/${action.id}`,
                method: 'POST',
                headers: {
                    'Authorization' : state$.value.user.info.idToken,
                    'Content-Type' : 'application/json',
                },
                body: {
                    watched
                }
            }).pipe(
                map(() => { toast(watched ? "Thanks for watching!" : "if you insist..."); return actions.noAction() })
            )
        })
    )
}

const INITIAL_STATE = {
    list : [],
    displayed : [],
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case SET_VIDEOS:
            state = {
                ...state,
                list: action.videos,
                displayed: action.videos,
            }
            break;

        case TOGGLE_VIDEO_WATCHED:
            const idx = _.findIndex(state.list, {'video':action.id});
            if (idx >= 0) {
                const video = state.list[idx];
                video.watched = !video.watched;
                const list = [
                    ...(_.slice(state.list, 0, idx)),
                    video,
                    ...(_.slice(state.list, idx+1)),
                ];
                state = {
                    ...state,
                    list,
                    displayed : state.list
                }
            }
            break;

        case FILTER_WATCHED:
            if (action.shouldFilter) {
                state = {
                    ...state,
                    displayed : state.list.filter(video => !video.watched)
                }
            } else {
                state = {
                    ...state,
                    displayed : state.list
                }
            }
            break;

        default: 
            break;
    }
    return state;
}