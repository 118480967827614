import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { select as userSelect } from '../redux/lib/user'
import { actions as videoAction, select as videoData } from '../redux/lib/videos'
import AddVideo from './AddVideo'
import Video from './Video'
import styled from 'styled-components'
import { Checkbox } from 'element-react'

const Table = styled.table`
    max-width:50em;
    position:relative;
    margin: 0 auto;
`

const HeaderCell = styled.th`
    font-weight:bold;
    padding: 0.5em 0.25em;
`

const HeaderRow = styled.tr`
    background-color:rgba(0,100,100,0.15)
`

const Img = styled.img`
    // opacity:0.5;
    width:90%;
    margin:0 auto;
`
const H1 = styled.h1`
    color: #bfbfbf;
    margin: 0em 3em;
    font-size:4em;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    row-gap:1em;
    margin-bottom:1em;
`

const Row = styled.div`
    display: contents;

    &:hover {
        background-color: rgba(46, 245, 208, 0.25);
        transition: 0.5s;
    }
`

const FilterOptions = () => {

    const val = true
    const [isChecked, setIsChecked] = useState(false)

    if (isChecked) {
        console.log(":)")
    }
    console.log(isChecked)

    const FilterOptions = styled.div`
        padding:1em 0;
        margin:0 auto;
        text-align:center;
    `

    // TODO remember the user's preference  
    const label = isChecked ? "Show Watched" : "Hide Watched";

    const dispatch = useDispatch()

    return (
        <FilterOptions>
            <Checkbox.Group size="large" fill="#324057" textColor="#a4aebd" onChange={() => { 
                    setIsChecked(!isChecked) 
                    dispatch(videoAction.filterWatched(!isChecked))
                 }}>
                <Checkbox.Button label={label} />
            </Checkbox.Group>
        </FilterOptions>
    )

}

export default () => {

    const dispatch = useDispatch()
    const videos = videoData.useDisplayedVideos()
    
    const isLoggedIn = userSelect.useIsLoggedIn()

    useEffect(() => {
        console.log("In useEffect, isLoggedIn?", isLoggedIn)
        if (isLoggedIn) {
            dispatch(videoAction.fetchVideos())


        }
    }, []) // TODO: Reload every render?

    console.log("videos", videos)
    
    
    return (
        <div>
            { isLoggedIn ? 
                <>
                    <AddVideo />
                    <FilterOptions />
                    <Table>
                        <thead>
                            <HeaderRow>
                                <HeaderCell>Video</HeaderCell>
                                <HeaderCell>Watched</HeaderCell>
                                <HeaderCell>Description</HeaderCell>
                                <HeaderCell>Email</HeaderCell>
                                <HeaderCell>Delete</HeaderCell>
                            </HeaderRow>
                        </thead>
                        <tbody>
                            {videos && videos.map( (video, idx) => 
                                <Video key={idx} video={video} />
                            )}
                        </tbody>
                    </Table>
                </>
                : 
                <>
                    <H1>Lunch and Learn Videos</H1>
                    <Img src="play.png" />
                </>
            }

        </div>
    )

}