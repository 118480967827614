import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import VideoList from './comp/VideoList'


import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import { actions as userActions, select as userSelect } from './redux/lib/user'


Amplify.configure(awsconfig);


const SignOut = styled.li`
  cursor:pointer;
`
const SignIn = styled.li`
  cursor:pointer;
`
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // color: #444;
  // top:5em;
  // position:absolute;
  // height:100%;

const VideoArea = styled.div`
  width:100%;
  top:5em;
  position:absolute;
`

const App = () => {

  const dispatch = useDispatch()
  const name = userSelect.useName();
  
  console.log("Have name", name)

  Hub.listen("auth", async ({ payload: { event, data } }) => {
    console.log("Auth event", event, data)
    switch (event) {
      case "signIn":
        dispatch(userActions.fetchUserInfo())
        break;
    //   case "signOut":
    //     this.setState({ user: null });
    //     break;
    //   case "customOAuthState":
    //     this.setState({ customState: data });
      default:
        break;
    }
  });
  // const name="";

  useEffect(() => {
    const checkLogin = async () => {
        dispatch(userActions.fetchUserInfo())
    }
    checkLogin()
  }, [])
  


  return (
    <>
      <div className="nav">
        <div className="content">
          <div>
            { name ? `Welcome, ${name}` : ''}
          </div>
          <ul>
            { name ? 
              <SignOut onClick={() => Auth.signOut()}>Sign out</SignOut> : 
              <SignIn onClick={() => Auth.federatedSignIn({provider: 'Google'})}>Sign in</SignIn>
            }
          </ul>
        </div>
      </div>
      <VideoArea>
        <VideoList />
      </VideoArea>
    </>
  );
}

export default App;
